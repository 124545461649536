import React from 'react';
import ImageBackground from './ImageBackground';

export default function Staff() {

    return (
        <div className="section features lazy purple">

            <ImageBackground />

            <div className="container">
                <div className="clearfix">
                    <div className="sixteen columns center">
                        <h2>Previous and Current Work</h2>
                        <p>
                            Arena, ​DAGA, DCCC, Democracy Alliance, EMILY′s List, ​Major campaigns ​for Governorships, US Senate, US House, and NYC Mayor.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );

}
