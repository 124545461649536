import React from 'react';

export default function Services() {

    return (
        <div className="section gray no-border-top reduce-top-padding">
            <div className="container">
                <div className="sixteen columns center clearfix">
                    <div className="title-group">
                        <h2>Our Services</h2>
                    </div>
                </div>
                <div className="profile-row">
                    <div className="seven offset-one columns profile-column">
                        <div className="profile fixed-center">
                            <div className="info">
                                <h4>Finance Director and <br />Senior Fundraising Advisor Services</h4>
                                <p className="title">
                                    For campaigns, political organizations and nonprofits looking to establish a top tier fundraising shop:
                                </p>
                                </div>
                                <ul>
                                    <li><p>Write an innovative finance plan that involves national and in-state outreach, allied group support, direct mail, digital and other buckets of money.</p> </li>
                                    <li><p>Provide a 360 review of current fundraising processes, diagnose the changes needed to transform into an elite fundraising department and make recommendations.</p></li>
                                    <li><p>Create systems that allow the fundraising department to flourish with or without our regular oversight.</p></li>
                                    <li><p>Train staff and principals on the ground on basic, intermediate and advanced skills; then continue to provide hands-on coaching throughout tenure.</p></li>
                                    <li><p>Construct a call time solicitation program with the trust of the principal, including the submission of targets and appropriate follow up procedures.</p></li>
                                    <li><p>Work with staff and other consultants to maximize efficiency and capacity.</p></li>
                                    <li><p>Establish the right structure to bridge a temporary gap in your campaign leadership or become permanent members of your team.</p></li>
                                </ul>
                        </div>
                    </div>
                    <div className="seven offset-one columns profile-column">
                        <div className="profile fixed-center">
                            <div className="info">
                                <h4>National Fundraising Services</h4>
                                <p className="title">
                                    For campaigns, political organizations, and nonprofits looking to increase their major donor fundraising nationally:
                                </p>
                            </div>
                            <ul>
                                <li><p>Nationwide major donor fundraising, with the option to focus on specific regions.</p></li>
                                <li><p>Major donor introductions, relationship cultivation, and direct solicitations.</p></li>
                                <li><p>Development of a national travel plan to maximize efficiency and capacity.</p></li>
                                <li><p>Staffing support for national fundraising travel.</p></li>
                                <li><p>Rolodexing and relationship mapping.</p></li>
                                <li><p>Event planning and management from concept to execution, including virtual and in-person events.</p></li>
                                <li><p><b>Donor Advising.</b></p></li>
                            </ul>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );

}
