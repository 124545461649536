import Home from './components/Home';
import Services from './components/Services';
import Work from './components/Work';
import Staff from './components/Staff';

import './base.css';

function App() {

  return (
    <section>
      <Home />
      <Services />
      <Work />
      <Staff />
    </section>
  );
}

export default App;
