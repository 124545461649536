import React from 'react';
import ImageBackground from './ImageBackground';

export default function Staff() {

    return (
        <div className="section features gray no-border-top reduce-top-padding">

            <ImageBackground />

            <div className="container">
                <div className="clearfix">
                    <div className="sixteen columns center">
                        <h2>Who We Are</h2>
                        <p>
                            <b>CDE Strategies</b> is a three-woman consultancy with over 40 years of combined experience. We believe that fundraising is not a one-size-fits-all industry and prioritize integrating fully into each campaign or organization to act as a partner in success.
                        </p>
                    </div>
                </div>
                <div className="features-list clearfix">
                    <div className="third columns">
                        <div className="description">
                            <h3>Diana Fassbender</h3>
                            <p>
                                With over 2 decades of experience as a fundraiser, political strategist, and donor advisor, Diana has worked with first time candidates and incumbent elected officials up and down the ballot, start-up and establishment political organizations, political philanthropists, and progressive nonprofits.
                            </p>
                        </div>
                    </div>
                    <div className="third columns">
                        <div className="description">
                            <h3>Emily Ison Hawkins</h3>
                            <p>
                                Emily currently serves as Senator Jon Ossoff&prime;s National Finance Director. She has designed his fundraising strategy throughout vastly different periods. She also served as Senator Jacky Rosen&prime;s National Finance Director from 2017-2018, building out the first term Congresswoman&prime;s network as she ran for Senate. She has been focused on in-house US Senate fundraising for over eight years, including for Senator Kay Hagan (NC), Deborah Ross (NC), and Dan Baer (CO). In the 2022 cycle, she has served as interim National Finance Director for two US Senate races and one US House race so far.
                            </p>
                        </div>
                    </div>
                    <div className="third columns">
                        <div className="description">
                            <h3>Caroline Saudek</h3>
                            <p>
                                With 15 years of experience, Caroline has helped raise hundreds of millions of dollars for major cultural institutions, start-up nonprofits, and record-breaking political campaigns.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

}
