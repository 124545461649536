import React from 'react';
import ImageBackground from './ImageBackground';

export default function Home() {

    return (
        <div className="section top lazy purple">

            <ImageBackground />

            <div className="container hero-parallax" style={{ top: 0 }}>
                <div className="sixteen columns">

                    <h1 className="title">
                        CDE Strategies
                    </h1>

                    <p className="tagline">
                        <b>CDE Strategies</b> offers a wide variety of fundraising services for political campaigns, political organizations, and nonprofits.
                        Our approach combines art and science, data and relationships, creative long-term strategies, relentless focus on detail, and goal-surpassing efforts.
                    </p>

                </div>

                <div className="center">
                    <div className="ten columns offset-three">
                        <h2>We&prime;d love to work with you</h2>
                        <p className="tagline">
                            Please email us: <a href="mailto:info@cdestrat.com">info@cdestrat.com</a>
                        </p>
                    </div>
                </div>

            </div>



            <div className="sep-dual sep-gray"></div>
            <div className="arrow"></div>
        </div>
    );

}
